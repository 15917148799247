import flipping from 'flipping-cards';
import flipping2 from 'flipping-cards2';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    homeResearchPartnersSlider();
    homeServicePartnersSlider();

    // setInterval(function () {
    //   homeResearchPartnersSlider();
    //   homeServicePartnersSlider();
    // }, 5000);
  },
};

// function generateRandom(min, max, except) {
//   var num = Math.floor(Math.random() * (max - min + 1)) + min;
//   return (except && num === except) ? generateRandom(min, max) : num;
// }

// function homeResearchPartnersSlider() {
//   let researchPartnersCount = document.getElementById('research-partners-count').value;

//   let randomElements = $('.research-partners-section .flip-container').filter('div:not(.active)').get().sort(function(){
//     return Math.round(Math.random())-0.5
//   }).slice(0,1);

//   $('.research-partners-section .flip-container').removeClass('active');
//   $(randomElements).addClass('active');

//   let activeCard = $('.research-partners-section .flipper-item .active .card-item');

//   activeCard.removeClass('front');
//   activeCard.removeClass('back');

//   let random = generateRandom(0, researchPartnersCount);
//   let random2 = generateRandom(0, researchPartnersCount, random);

//   if(random === random2 || (activeCard.eq(random).hasClass('front') && activeCard.eq(random2).hasClass('back'))) {
//     random = generateRandom(0, researchPartnersCount);
//     random2 = generateRandom(0, researchPartnersCount, random);
//   }

//   activeCard.eq(random).addClass('front');
//   activeCard.eq(random2).addClass('back');
// }

// function homeServicePartnersSlider() {
//   let servicePartnersCount = document.getElementById('service-partners-count').value;

//   let randomElements = $('.service-partners-section .flip-container').filter('div:not(.active)').get().sort(function(){
//     return Math.round(Math.random())-0.5
//   }).slice(0,1);

//   $('.service-partners-section .flip-container').removeClass('active');
//   $(randomElements).addClass('active');

//   let activeCard = $('.service-partners-section .flipper-item .active .card-item');

//   activeCard.removeClass('front');
//   activeCard.removeClass('back');

//   let random = generateRandom(0, servicePartnersCount);
//   let random2 = generateRandom(0, servicePartnersCount, random);

//   if(random === random2 || (activeCard.eq(random).hasClass('front') && activeCard.eq(random2).hasClass('back'))) {
//     random = generateRandom(0, servicePartnersCount);
//     random2 = generateRandom(0, servicePartnersCount, random);
//   }

//   activeCard.eq(random).addClass('front');
//   activeCard.eq(random2).addClass('back');
// }

$(window).on('resize', function () {
  homeResearchPartnersSlider();
  homeServicePartnersSlider();
});

function homeResearchPartnersSlider() {
  if ($('.research-partners-section .cards .card-item').length > 6) {
    $('.research-partners-section .flip-wrapper').addClass('flipping');
    $('.research-partners-section .flip-wrapper').attr('id', 'r_flipping_cards');

    var cardsPerRowValue = '';

    if (matchMedia('screen and (min-width: 1400px)').matches ) {
      cardsPerRowValue = '3';
    } else if (matchMedia('screen and (min-width: 992px)').matches ) {
      cardsPerRowValue = '2';
    } else {
      cardsPerRowValue = '1';
    }

    var r_configuration = {
      autoFlipMode: true,
      autoFlipDelay: 5000,
      pauseMouseOver: true,
      transitionDuration: 700,
      rotationMode: 'sequential',
      sequentialDelay: 2500,
      spacingHorizontal: 12,
      spacingVertical: 12,
      cardsToShow: 6,
      cardsPerRow: cardsPerRowValue,
      startFromIndex: 1,
      cardsShadow: false,
      buttonsShadow: false,
    };

    flipping2.init('r_flipping_cards', r_configuration);
  } else {
    $('.research-partners-section .flip-wrapper').removeClass('flipping');
    $('.research-partners-section .flip-wrapper').attr('id', '');
  }
}

function homeServicePartnersSlider() {
  if ($('.service-partners-section .cards .card-item').length > 6) {
    $('.service-partners-section .flip-wrapper').addClass('flipping');
    $('.service-partners-section .flip-wrapper').attr('id', 's_flipping_cards');

    var cardsPerRowValue = '';

    if (matchMedia('screen and (min-width: 1400px)').matches ) {
      cardsPerRowValue = '3';
    } else if (matchMedia('screen and (min-width: 992px)').matches ) {
      cardsPerRowValue = '2';
    } else {
      cardsPerRowValue = '1';
    }

    var s_configuration = {
      autoFlipMode: true,
      autoFlipDelay: 5000,
      pauseMouseOver: true,
      transitionDuration: 700,
      rotationMode: 'sequential',
      sequentialDelay: 2500,
      spacingHorizontal: 12,
      spacingVertical: 12,
      cardsToShow: 6,
      cardsPerRow: cardsPerRowValue,
      startFromIndex: 1,
      cardsShadow: false,
      buttonsShadow: false,
    };

    flipping.init('s_flipping_cards', s_configuration);
  } else {
    $('.service-partners-section .flip-wrapper').removeClass('flipping');
    $('.service-partners-section .flip-wrapper').attr('id', '');
  }
}
