import { wrapGrid } from 'animate-css-grid';

export default {
  init() {
    // JavaScript to be fired on the team page
  },
  finalize() {
    // JavaScript to be fired on the team page, after the init JS

    $('.filter-name').click(function(){
      $('.filter-items').slideToggle('slow');
      $('.filter-name').toggleClass('opened');
    });

    $('.content-inner-wrp .close-box').on('click', function(){
      $('.content-inner-wrp .close-box').removeClass('content-expanded');
    });

    const grid = document.querySelector('.member-posts');

    wrapGrid(grid, {
      duration: 350,
      stagger: 10,
      easing: 'easeInOut',
      onStart: elements =>
        console.log(`started animation for ${elements.length} elements`),
      onEnd: elements =>
        console.log(`finished animation for ${elements.length} elements`),
    });
  },
};
